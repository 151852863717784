<template>
  <div id="mapContainer2d" class="map-container">
    <img :src="NoImage" style="width: 200px; height: 200px" v-if="showNoService" />
    <el-button
      type="primary"
      data-html2canvas-ignore
      @click="getImage"
      style="position: absolute; top: 10px; right: 10px; z-index: 500"
      v-if="!isDisabled"
      >更新缩略图</el-button
    >
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { init2DMap } from './util.js';
import { mapOptions, list2DMap, list3DMap, hash3DMap } from '@/views/services-center/space/components/map2d/dataCenter';
import NoImage from '@/assets/img/no-img2.svg';
import html2canvas from 'html2canvas';
import { imgUpload } from '@/api/project/project';
import { updateSpaceDetail } from '@/api/cim';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'Map2d',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: () => '2d',
    },
  },
  setup() {
    const showNoService = ref(false);
    const containerId = 'mapContainer2d';
    const route = useRoute();
    const isDisabled = route.fullPath.includes('service-resource-center');
    const init = () => {
      const item = {
        container: containerId,
        ...mapOptions.value,
      };
      if (list2DMap.includes(item.type)) {
        return init2DMap(item);
      }
      if (list3DMap.includes(item.type)) {
        return hash3DMap[item.type](containerId, item.url);
      }
      showNoService.value = true;
    };

    const dataURItoBlob = (dataURI) => {
      // base64 解码
      const byteString = window.atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // const T = mimeString.split('/')[1];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    };

    const getImage = () => {
      const containers = document.getElementById(containerId);
      html2canvas(containers, {
        backgroundColor: null,
        useCORS: true,
        allowTaint: false,
        scale: 1,
        taintTest: true,
        x: 0,
        y: 0,
      }).then((canvas) => {
        const src = canvas.toDataURL('image/jpeg', 0.2);
        const a = document.createElement('a');
        a.href = src;
        a.download = mapOptions.value.serviceName || 'map_picture';
        const file = dataURItoBlob(src);
        console.log(file.size);
        if (file.size >= 1024 ** 2 * 5) {
          return ElMessage.error('截图大小不得超过5MB，请缩放页面尺寸重新截图');
        }
        a.click();
        const formData = new FormData();
        formData.append('file', file);

        imgUpload(formData).then((res) =>
          updateSpaceDetail({
            id: mapOptions.value.id,
            serviceThum: res.data.fileKey,
          }),
        );
      });
    };

    watch(mapOptions, () => {
      init();
    });

    return {
      NoImage,
      showNoService,
      getImage,
      isDisabled,
    };
  },
});
</script>

<style lang="scss">
.map-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ol-zoom {
    display: none;
  }
  .cesium-viewer-bottom {
    display: none;
  }
}
</style>
