/* eslint-disable */
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileWMS from 'ol/source/TileWMS';
import { OSM, ImageArcGISRest } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer';
import { addProjection, get as getProjection, Projection } from 'ol/proj';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import { TileSuperMapRest } from '@supermap/iclient-ol';
import XYZ from 'ol/source/XYZ';
import EsriJSON from 'ol/format/EsriJSON';
import TileGrid from 'ol/tilegrid/TileGrid';
import { getWidth } from 'ol/extent';

import { ref } from 'vue';

// 声明4490坐标系
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import TileArcGISRest from 'ol/source/TileArcGISRest';

const mapKey4490 = {
  esri:
    'GEOGCS["China Geodetic Coordinate System 2000",DATUM["D_China_2000",SPHEROID["CGCS2000",637' +
    '8137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]]',
  proj4: '+proj=longlat +ellps=GRS80 +no_defs ',
  ogc:
    'GEOGCS["China Geodetic Coordinate System 2000",DATUM["China_2000",SPHEROID["CGCS2000",63' +
    '78137,298.257222101,AUTHORITY["EPSG","1024"]],AUTHORITY["EPSG","1043"]],PRIMEM["Greenwich",0,AUTHORI' +
    'TY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4490"]]',
};
proj4.defs('EPSG:4490', mapKey4490.esri);
register(proj4);
const projection4490 = new Projection({
  code: 'EPSG:4490',
  units: 'degrees',
  axisOrientation: 'neu',
});
projection4490.setExtent([-180, -90, 180, 90]);
projection4490.setWorldExtent([-180, -90, 180, 90]);
addProjection(projection4490);
// 声明结束

export const fetchRequest = (url, params = {}, timeout = 10000) => {
  let isTimeout = false;
  return new Promise((resolve, reject) => {
    const TO = setTimeout(() => {
      isTimeout = true;
      reject(new Error('Fetch timeout'));
    }, timeout);

    fetch(url, params)
      .then((res) => {
        clearTimeout(TO);
        if (!isTimeout) {
          resolve(res);
        }
      })
      .catch((e) => {
        if (isTimeout) {
          return;
        }
        reject(e);
      });
  });
};
// 全局地图对象
const mapObj = ref(null);

// esri feature json format声明
export const esrijsonFormat = new EsriJSON();

// wmts parse对象声明
const parser = new WMTSCapabilities();

// 获取wmts切片元数据
const getWMTSSourceData = async (url, layer, projection) => {
  const response = await fetch(url);
  const result = await parser.read(await response.text());
  const data = optionsFromCapabilities(result, {
    layer,
    matrixSet: projection,
  });
  if (url.includes('?tk') || url.includes('&tk')) {
    const tk = new URL(url).searchParams.get('tk');
    const changeUrl = data.urls[0];
    data.urls[0] = `${changeUrl}tk=${tk}`;
  }
  return data;
};

// 获取arcgis rest map feature layer source-data
const getRestFeatureSource = async (url, layerJson) => {
  const { wkid } = layerJson.extent.spatialReference;
  const { drawingInfo, geometryType } = layerJson;
  const { symbol } = drawingInfo.renderer;
  const styleMap = {
    esriGeometryPoint: symbol.imageData
      ? new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: `data:image/png;base64,${symbol.imageData}`,
          }),
        })
      : new Style({
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({ color: '#50e3c2' }),
            stroke: new Stroke({
              color: '#0081e2',
              width: 2,
            }),
          }),
        }),
    esriSMSCircle: new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: '#50e3c2' }),
        stroke: new Stroke({
          color: '#0081e2',
          width: 2,
        }),
      }),
    }),
    esriGeometryPolyline: new Style({
      stroke: new Stroke({
        color: '#0081e2',
        width: 2,
        size: 2,
      }),
      fill: new Fill({ color: '#50e3c2' }),
    }),
    esriGeometryPolygon: new Style({
      fill: new Fill({ color: '#50e3c2' }),
      stroke: new Stroke({
        color: '#0081e2',
        width: 2,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: '#50e3c2' }),
        stroke: new Stroke({
          color: '#0081e2',
          width: 2,
        }),
      }),
    }),
  };

  const vectorSource = new VectorSource({});
  const urls = `${url}/query?where=1%3D1&f=pjson`;
  const response = await fetch(urls);
  const data = await response.json();

  const ndata = esrijsonFormat.readFeatures(data, {
    featureProjection: `ESPG:${wkid}`,
  });
  ndata.forEach((x) => {
    x.setStyle(styleMap[geometryType]);
  });

  vectorSource.addFeatures(ndata);

  return new VectorLayer({
    source: vectorSource,
  });
};

// 预加载图层信息，为获取图层
const getLayerType = async (url) => {
  const response = await fetch(url.includes('serviceId') ? `${url}&f=pjson` : `${url}?f=pjson`);
  return await response.json();
};

// wfs预加载feature函数，暂时与super map不兼容，后续处理
const getSource = (url, projection, layer) =>
  new VectorSource({
    format: new GeoJSON(),
    url(extent) {
      const base =
        `${url}?SERVICE=WFS&VERSION=1.0.0&REQUEST=GetFeature&outputFormat` +
        `=application/json&typename=World:${layer}&srsname=${projection}`;
      if (extent) {
        return `${base}&bbox=${extent.join(',')}`;
        // ,${projection}
      }
      return base;
    },
    strategy: bboxStrategy,
  });

// 底图影像
const getRaster = () =>
  new TileLayer({
    source: new XYZ({
      url: `https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
      maxZoom: 20,
    }),
  });
// 声明osm底图切片
export const osmRaster = () =>
  new TileLayer({
    source: new OSM(),
  });

// 计算wms的栅格尺寸
const computeWmsGride = (projection, extent, tileSize = [256, 256]) => {
  const projExtent = getProjection(projection).getExtent();
  const startResolution = getWidth(projExtent) / tileSize[0];
  const resolutions = new Array(22);
  for (let i = 0, ii = resolutions.length; i < ii; ++i) {
    resolutions[i] = startResolution / 2 ** i;
    // Math.pow(2, i)
  }
  return new TileGrid({
    extent,
    resolutions,
    tileSize,
  });
};

const getMaxResolution = (format) => {
  switch (format) {
    case 'PNG24':
      return 360 / 256;
    // case 'MIXED':
    //   return 180 / 256;
    default:
      return undefined;
  }
};

// 获取arcgis rest map layer
const getArGisLayer = async ({ url, projection, extent }) => {
  let layers = {};
  const urlObj = new URL(url);
  const nUrl = urlObj.origin + urlObj.pathname;
  const serviceId = urlObj.searchParams.get('serviceId');
  const layerJson = await getLayerType(url);
  if (layerJson.type && layerJson.type.includes('Feature')) {
    // 兼容捷泰旧的不规范的feature layer
    layers = await getRestFeatureSource(url, layerJson, extent);
  } else if (!layerJson.tileInfo) {
    // export map 形式加载rest map feature
    const params = {
      FORMAT: layerJson?.supportedImageFormatTypes?.split(',')[0] || 'PNG32',
    };
    if (serviceId !== null) {
      params.serviceId = serviceId;
    }
    layers = new TileLayer({
      extent,
      source: new TileArcGISRest({
        // ratio: 1,
        params,
        url: nUrl,
        projection: getProjection(projection),
        tileSize: 256,
        crossOrigin: `anonymous`,
      }),
    });
  } else if (!layerJson.layers || !layerJson.supportsDynamicLayers || layerJson.tileInfo) {
    // xyz形式加载rest map静态切片
    let urlWidthId = `${nUrl}/tile/{z}/{y}/{x}?blankTile=false`;
    if (serviceId !== null) {
      urlWidthId = `${urlWidthId}&serviceId=${serviceId}`;
    }
    layers = new TileLayer({
      source: new XYZ({
        url: urlWidthId,
        crossOrigin: `anonymous`,
        projection,
        wrapX: true,
        tileSize: 256,
        // 捷泰数据在PNG24的格式下需特殊处理分辨率，其他格式下最大分辨率必须微undefined，否则渲染进程卡死
        maxResolution: getMaxResolution(layerJson?.tileInfo?.format),
        // layerJson.tileInfo && layerJson.tileInfo?.format !== 'PNG24' ? undefined : 360 / 256,
      }),
    });
  } else {
    // 否则image形式加载，兜底，防止堆栈溢出
    layers = new ImageLayer({
      source: new ImageArcGISRest({
        params: {
          FORMAT: layerJson?.supportedImageFormatTypes?.split(',')[0] || 'PNG32',
          serviceId,
        },
        url: nUrl,
      }),
    });
  }

  return layers;
};

// 获取OGC Wms layer
const getOGCWmsLayer = ({ url, extent, layer, projection }) => {
  const source = new TileWMS({
    url,
    params: {
      LAYERS: `${layer[0]}`,
      TILED: true,
      STYLES: 'default',
    },
    transition: 0,
    projection: getProjection(projection),
    tileGrid: computeWmsGride(projection, extent, [256, 256]),
    crossOrigin: `anonymous`,
  });
  return new TileLayer({
    extent,
    source,
  });
};

// 根据服务类型声明相应的图层对象
const caseLayer = async (type, { url, layer, projection, extent }) => {
  let layers = {};
  switch (type) {
    case 'GeoScene REST Map服务':
      layers = await getArGisLayer({ url, projection, extent });
      break;
    case 'OGC WMS服务':
      layers = getOGCWmsLayer({ url, extent, layer, projection });
      break;
    case 'OGC WMTS服务':
      layers = new TileLayer({
        source: new WMTS(await getWMTSSourceData(url, layer[0], projection)),
      });
      break;
    case 'OGC WFS服务':
      layers = new VectorLayer({
        source: getSource(url, projection, layer[0]),
        crossOrigin: `anonymous`,
        style: new Style({
          stroke: new Stroke({
            color: 'rgba(0, 0, 255, 1.0)',
            width: 2,
          }),
        }),
      });
      break;
    case 'SuperMap REST Map服务':
      layers = new TileLayer({
        source: new TileSuperMapRest({
          crossOrigin: `anonymous`,
          url,
          wrapX: true,
        }),
        projection: getProjection(projection),
        extent,
      });
      break;
    default:
      layers = getRaster();
      break;
  }

  return layers;
};

// 初始化2d地图
export const init2DMap = async ({ container, type, url, center, projection, layer, extent }) => {
  const getLayer = await caseLayer(type, { url, projection, layer, extent });
  const view = new View({
    center,
    zoom: 9,
    projection: getProjection(projection),
  });
  mapObj.value = new Map({
    layers: [getLayer],
    target: container,
    view,
  });
  // 超图插件需要视口变化才会初始化
  setTimeout(() => {
    view.fit(extent);
    if (type?.includes('Super')) {
      view.animate({ zoom: view.getZoom() + 1 }, { center });
    } else {
      view.animate({ zoom: view.getZoom() }, { center });
    }
  }, 100);
};
