import { ref } from 'vue';

import {
  initFreedo3DTiles,
  initCesium3d,
  initI3SData,
  initS3MData,
} from '@/views/services-center/space/components/map2d/3DTiles';

export const mapOptions = ref({});

export const list2DMap = [
  'GeoScene REST Map服务',
  'OGC WMS服务',
  'OGC WMTS服务',
  'OGC WFS服务',
  'SuperMap REST Map服务',
];

export const list3DMap = ['3DTiles数据服务', '3DTiles数据集服务', 'GeoScene REST Scene服务', 'SuperMap REST 3D服务'];

export const hash3DMap = {
  '3DTiles数据服务': initCesium3d,
  '3DTiles数据集服务': initFreedo3DTiles,
  'GeoScene REST Scene服务': initI3SData,
  'SuperMap REST 3D服务': initS3MData,
};
