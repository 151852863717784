/* eslint-disable */
const { WETS, Cesium } = window;
function getMatrixSetFromID(jsonData, matrixSetIdentifier) {
  return jsonData.contents.matrixsets.find((element) => element.identifier === matrixSetIdentifier);
}

function getMatrixSets(jsonData, layer) {
  const matrixSets = [];
  layer.matrixsetlink.forEach((matrixsetlink) => {
    const matrixSet = getMatrixSetFromID(jsonData, matrixsetlink);
    if (typeof matrixSet === 'undefined') {
      throw new Error('');
    }

    matrixSets.push(matrixSet);
  });
  return matrixSets;
}

function getResourceTemplateURL(layer, matrixSet, resourceType) {
  if (layer.resourceurl) {
    return layer.resourceurl.find((element) => element.type === resourceType).template;
  }
  if (matrixSet.resourceurl) {
    return matrixSet.resourceurl.find((element) => element.type === resourceType).template;
  }
}

function getTilesetURL(layer, matrixSet, matrix, token, url) {
  if (Cesium.defined(matrix.url)) {
    return matrix.url;
  }
  const matrixSetIdentifier = matrixSet.identifier;
  const matrixIdentifier = matrix.identifier;
  let template = getResourceTemplateURL(layer, matrixSet, 'file');
  if (!template.includes('http')) {
    template = url + template;
  }
  template = template.replace(/{matrixset}/g, matrixSetIdentifier);
  if (template.indexOf('{token}') > 0) {
    template = template.replace(/{token}/g, matrix.token);
    template = template.replace(/{matrix}/g, matrix.title);
    template = template.replace(/{filepath}/g, 'tileset.json');
  } else {
    template = template.replace(/{matrix}/g, matrixIdentifier);
    template = template.replace(/{filepath}/g, matrixIdentifier);
    if (typeof token !== 'undefined') {
      template = `${template}?token=${token}`;
    }
  }

  return template;
}

// 飞渡DEM模型
const demUrl =
  'http://10.1.213.173/FreedoMetaSvc/interact/meta/wets' +
  '/1.1.0/WETSCapabilities.xml?id=a9d8d0be-b726-4c71-8042-9a5aff263b51';

const config = {
  geocoder: false, // 位置查找工具
  homeButton: false, // 视角返回初始位置
  sceneModePicker: false, // 选择视角的模式（球体、平铺、斜视平铺）
  baseLayerPicker: false, // 图层选择器（地形影像服务）
  navigationHelpButton: false, // 导航帮助(手势，鼠标)
  animation: false, // 左下角仪表盘（动画器件）
  timeline: false, // 底部时间线
  fullscreenButton: false, // 全屏
  vrButton: false, // VR
  // 三维截图
  contextOptions: {
    webgl: {
      preserveDrawingBuffer: true,
    },
  },
};
const options = {
  animation: false, // 是否显示动画控件
  homeButton: true, // 是否显示home键
  // geocoder:false,         //是否显示地名查找控件        如果设置为true，则无法查询
  baseLayerPicker: false, // 是否显示图层选择控件
  timeline: false, // 是否显示时间线控件
  fullscreenButton: true, // 是否全屏显示
  scene3DOnly: true, // 如果设置为true，则所有几何图形以3D模式绘制以节约GPU资源
  infoBox: true, // 是否显示点击要素之后显示的信息
  sceneModePicker: false, // 是否显示投影方式控件  三维/二维
  navigationInstructionsInitiallyVisible: false,
  navigationHelpButton: false, // 是否显示帮助信息控件
  selectionIndicator: false, // 是否显示指示器组件
  showRenderLoopErrors: true,
  // 三维截图
  contextOptions: {
    webgl: {
      preserveDrawingBuffer: true,
    },
  },
};

export const initFreedo3DTiles = (container, url) => {
  const viewer = new Cesium.Viewer(container, config);
  // 加载DEM
  viewer.terrainProvider = new WETS.WETSTerrainProvider({
    sources: [
      {
        capabilitiesUrl: demUrl,
      },
    ],
  });
  viewer._cesiumWidget._creditContainer.style.display = 'none';
  let token;
  const { origin } = window.location;
  const urlObj = new URL(url.includes('http') ? url : origin + url);
  const paramsObj = urlObj.searchParams;
  const queryParameters = Object.fromEntries(paramsObj?.entries()) || {};
  if (url.indexOf('token') > -1) {
    token = paramsObj.get('token');
  }
  Cesium.Resource.fetchJson({
    url: urlObj.origin + urlObj.pathname,
    queryParameters,
  }).then((jsonData) => {
    if (jsonData?.contents) {
      jsonData.contents.layers.forEach((layer) => {
        const matrixSets = getMatrixSets(jsonData, layer);
        matrixSets.forEach((matrixSet) => {
          matrixSet.matrix.forEach((matrix) => {
            const tilesetUrl = getTilesetURL(layer, matrixSet, matrix, token, urlObj.origin);
            // tilesetUrl = `${tilesetUrl}?serviceId=${paramsObj.get('serviceId')}`;
            const tileset = viewer.scene.primitives.add(
              new Cesium.Cesium3DTileset({
                url: tilesetUrl,
                maximumScreenSpaceError: 4,
              }),
            );
            tileset.readyPromise
              .then((tileset) => {
                // eslint-disable-next-line max-len
                viewer.zoomTo(tileset, new Cesium.HeadingPitchRange(0.0, -0.5, tileset.boundingSphere.radius * 2.0)); // 定位到模型
              })
              .otherwise((err) => console.log(err));
          });
        });
      });
    }
  });
};

export const initCesium3d = (container, url) => {
  const viewer = new Cesium.Viewer(container, options);
  viewer._cesiumWidget._creditContainer.style.display = 'none';
  // 去除版权信息

  viewer.terrainProvider = new WETS.WETSTerrainProvider({
    sources: [
      {
        capabilitiesUrl: demUrl,
      },
    ],
  });

  const tileset = viewer.scene.primitives.add(
    new Cesium.Cesium3DTileset({
      url,
    }),
  );
  viewer.scene.primitives.add(tileset);

  tileset.readyPromise
    .then((tileset) => {
      viewer.scene.primitives.add(tileset);
      viewer.zoomTo(tileset, new Cesium.HeadingPitchRange(0.5, -0.2, tileset.boundingSphere.radius * 1.0));
      // 高程归零
      // const height = 0;
      // const cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center);
      // const surface = Cesium.Cartesian3.fromRadians
      // (cartographic.longitude, cartographic.latitude, cartographic.height);
      // const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, height);
      // const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
      // eslint-disable-next-line no-param-reassign
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    })
    .otherwise((error) => {
      console.log(error);
    });
};

export const initI3SData = (container, url) => {
  const viewer = new Cesium.Viewer(container, options);
  viewer._cesiumWidget._creditContainer.style.display = 'none';

  const dataSource = new Cesium.I3SDataSource('i3s', viewer.scene, {
    autoCenterCameraOnStart: true, // auto center to the location of the i3s
  });

  dataSource.camera = viewer.camera;
  dataSource.loadUrl(url).then(() => {
    console.log(dataSource);
  });
  viewer.dataSources.add(dataSource);
};

export const initS3MData = async (container, url) => {
  const viewer = new Cesium.Viewer(container, options);
  const { scene } = viewer;

  scene.open(url);
};
