<template>
  <div class="space-detail" :class="{ 'page-size': isDisabled }">
    <div class="space-detail-content">
      <div class="content-item detail-title">
        <i class="el-icon-arrow-left back-icon" @click="handleBack" v-if="isDisabled"></i>
        <h3 style="display: inline-block">{{ spaceDetail.serviceName }}</h3>
        <button class="apply-btn" code="apply" @click="apply" v-if="isDisabled && IdentifyButton === 3">
          申请使用
        </button>
        <el-link
          class="apply-btn link"
          :underline="false"
          href="/service-resource-center/user-center/service-apply"
          target="_blank"
          v-else-if="isDisabled && IdentifyButton === 1"
          title="点击查看申请状态"
          >申请中</el-link
        >
      </div>
      <div class="content-item">
        <div class="tab-item">基本信息</div>
        <div class="tab-item">
          <label>服务类型</label>
          <span>{{ spaceDetail.serviceTypeName }}</span>
        </div>
        <div class="tab-item">
          <label>文件大小</label>
          <span>{{ spaceDetail.dataSize || 0 }} KB</span>
        </div>
        <div class="tab-item">
          <label>创建时间</label>
          <span>{{ spaceDetail.createTime }}</span>
        </div>
        <div class="tab-item">
          <label>更新时间</label>
          <span>{{ spaceDetail.updateTime }}</span>
        </div>
        <div class="tab-item">
          <label>数据来源</label>
          <el-input
            style="width: 185px"
            v-if="!isDisabled"
            v-model="spaceDetail.dataSource"
            @blur="updateDatasource"
            placeholder="至多20个字符...."
            maxlength="20"
          ></el-input>
          <span v-else>{{ spaceDetail.dataSource }}</span>
        </div>
        <div class="tab-item" v-if="isDisabled">
          <label>行政区划</label>
          <span>{{ spaceDetail.areaName }}</span>
        </div>
        <template v-else>
          <div class="tab-item">
            <label>行政区划</label>
            <el-select style="width: 185px" v-model="areaList.provence" placeholder="请选择省/直辖市">
              <el-option
                v-for="area in resolveCodes.provence"
                :key="area.id"
                :label="area.areaName"
                :value="area.areaCode"
              ></el-option>
            </el-select>
          </div>
          <div class="tab-item">
            <label></label>
            <el-select style="width: 185px" v-model="areaList.city" @change="changeCity" placeholder="请选择市级区域">
              <el-option
                v-for="area in resolveCodes.city"
                :key="area.id"
                :label="area.areaName"
                :value="area.areaCode"
              ></el-option>
            </el-select>
          </div>
          <div class="tab-item">
            <label></label>
            <el-select
              style="width: 185px"
              v-model="areaList.area"
              @change="updateAreaCode"
              placeholder="请选择区县级区域"
            >
              <el-option
                v-for="area in resolveCodes.area"
                :key="area.id"
                :label="area.areaName"
                :value="area.areaCode"
              ></el-option>
            </el-select>
          </div>
        </template>
      </div>
      <div class="content-item">
        <div class="tab-item">
          <label>数据范围</label>
        </div>
        <div class="tab-item">
          <span class="point">{{ spaceDetail.xMin }}&nbsp;Xmin</span>
          <span class="point">{{ spaceDetail.xMax }}&nbsp;Xmax</span>
        </div>
        <div class="tab-item">
          <span class="point">{{ spaceDetail.yMin }}&nbsp;Ymin</span>
          <span class="point">{{ spaceDetail.yMax }}&nbsp;Ymax</span>
        </div>
      </div>
      <div class="content-item" v-if="!isDisabled">
        <div class="tab-item">
          <label>服务地址</label>
        </div>
        <div class="tab-item">
          <span style="word-break: break-all"
            >{{ spaceDetail.serviceUrl }} &nbsp;&nbsp;
            <i class="el-icon-copy-document" @click="copyUrl('#hiddenInput')" v-if="spaceDetail.serviceUrl"></i
          ></span>
          <input
            :value="spaceDetail.serviceUrl"
            style="position: absolute; z-index: -10; opacity: 0"
            id="hiddenInput"
          />
        </div>
      </div>
      <div class="content-item">
        <div class="tab-item">
          <label>代理地址</label>
        </div>
        <div class="tab-item">
          <span style="word-break: break-all"
            >{{ spaceDetail.proxyUrl }} &nbsp;&nbsp;
            <i class="el-icon-copy-document" @click="copyUrl('#hiddenInputProxy')" v-if="spaceDetail.proxyUrl"></i
          ></span>
          <input
            :value="spaceDetail.proxyUrl"
            style="position: absolute; z-index: -10; opacity: 0"
            id="hiddenInputProxy"
          />
        </div>
      </div>
      <div class="content-item">
        <div class="tab-item">
          <label>服务描述</label>
        </div>
        <div class="tab-item">
          <el-input
            v-if="!isDisabled"
            type="textarea"
            v-model="spaceDetail.description"
            rows="7"
            @blur="updateDescription"
          ></el-input>
          <div v-else style="width: 100%; min-height: 60px">
            {{ spaceDetail.description }}
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="tab-item">
          <label>标签</label>
        </div>
        <div class="tab-item">
          <div class="service-tags">
            <div class="tag-item" v-for="(item, index) in spaceDetail.tags" :key="index">
              {{ item }}
              <i class="el-icon-circle-close close-icon" @click="deleteTags(item)" v-if="!isDisabled"></i>
            </div>
            <div class="tag-item add-tag" @click="dialogVisible = true" v-if="!isDisabled">+ 添加</div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-detail-pic">
      <!--      <img :src="spaceDetail.serviceThum" alt="图片加载失败" v-if="spaceDetail.serviceThum" @error="picError" />-->
      <!--      <img :src="NoImage" v-else style="width: 200px; height: 200px" />-->
      <MapPreview :options="mapOptions"></MapPreview>
    </div>
    <el-dialog title="添加标签" v-model="dialogVisible" width="400px">
      <el-form label-width="60px">
        <el-form-item label="输入：">
          <div style="width: 280px">
            <el-input
              v-model="tagString"
              placeholder="请输入标签，多个标签以英文逗号分隔"
              @blur="updateDescription"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="选择：">
          <el-select multiple clearable v-model="tagArr" style="width: 280px">
            <el-option v-for="(item, index) in tagList" :label="item" :value="item" :key="index"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitTags">确定</el-button>
      </template>
    </el-dialog>
    <apply-dialog
      v-if="isDisabled"
      ref="applyDialogRef"
      :addStatus="true"
      @getIdentifyButtonStatus="getIdentifyButtonStatus"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  getAreaCodes,
  getSpaceServiceDetail,
  getTagsWithCount,
  saveTag,
  updateSpaceDetail,
  getIdentifyButton,
  getRootAreaCode,
  getNextAreaCode,
} from '@/api/cim';
import { ElMessage } from 'element-plus';
import NoImage from '@/assets/img/no-img2.svg';
import MapPreview from '@/views/services-center/space/components/map2d/Index.vue';
import { mapOptions } from '@/views/services-center/space/components/map2d/dataCenter';
import { userInfo } from '@/layout/messageCenter/user-info';
import ApplyDialog from '@/views/services-center/space/components/ApplyDialog.vue';
// import _ from 'lodash';

export default defineComponent({
  name: 'SpaceDetail',
  components: {
    MapPreview,
    ApplyDialog,
  },
  setup() {
    // const isDevelopment = process.env.NODE_ENV === 'development';
    const spaceId = ref('');
    const route = useRoute();
    const router = useRouter();
    spaceId.value = route.params.id;
    const dialogVisible = ref(false);
    const tagString = ref('');
    const tagArr = ref([]);
    const tagList = ref([]);
    const spaceDetail = ref({});
    const isDisabled = route.fullPath.includes('service-resource-center');
    // 标识用户是否属于当前区域,默认属于当前区域
    const IdentifyButton = ref(false);
    const getIdentifyButtonStatus = async () => {
      const { data } = await getIdentifyButton(spaceId.value);
      IdentifyButton.value = data;
    };
    if (isDisabled) {
      getIdentifyButtonStatus();
    }
    const { origin } = window.location;

    const areasCodes = ref([]);
    const showAreaName = ref('');
    const getAreas = async () => {
      const { data } = await getAreaCodes(1);
      areasCodes.value = data;
    };
    getAreas();

    let casCodeData = [];

    const resolveCodes = ref([]);

    const areaList = ref([]);
    const getRootCodes = async (targetCode = '') => {
      if (targetCode.length === 9) {
        // eslint-disable-next-line no-param-reassign
        targetCode = targetCode.slice(0, 6);
      }
      const { data } = await getRootAreaCode();
      casCodeData = data;
      casCodeData.children = [];
      const [provence, city, area] = await Promise.all([
        getNextAreaCode(data.areaCode),
        getNextAreaCode(targetCode.slice(0, 2) || 0),
        getNextAreaCode(targetCode.slice(0, 4) || 0),
      ]);
      // _.find(city.data, (o) => `${o.areaCode}` === targetCode.slice(0, 4)).children = area.data;
      // _.find(provence.data, (o) => `${o.areaCode}` === targetCode.slice(0, 2)).children = city.data;
      resolveCodes.value = {
        provence: provence.data,
        city: city.data,
        area: area.data,
      };
      areaList.value = {
        provence: targetCode.slice(0, 2),
        city: targetCode.slice(0, 4),
        area: targetCode,
      };
    };

    watch(
      () => areaList.value.provence,
      async (nn) => {
        const { data } = await getNextAreaCode(nn);
        resolveCodes.value.city = data;
        areaList.value.city = data[0].areaCode;
      },
    );

    watch(
      () => areaList.value.city,
      async (nn) => {
        const { data } = await getNextAreaCode(nn);
        resolveCodes.value.area = data;
        // areaList.value.area = data[0].areaCode;
      },
    );

    const changeCity = () => {
      areaList.value.area = '';
    };

    const cascaderProps = {
      label: 'areaName',
      value: 'areaCode',
      checkStrictly: true,
    };
    const casChange = (node) => {
      console.log(node);
    };

    getRootCodes();

    const descriptionChanges = ref(false);
    const dataSourceChanges = ref(false);
    watch(
      () => spaceDetail.value.description,
      () => {
        descriptionChanges.value = true;
      },
    );
    watch(
      () => spaceDetail.value.dataSource,
      () => {
        dataSourceChanges.value = true;
      },
    );

    const computeProxyUrl = (url) => {
      let newUrl = '';
      if (process.env.NODE_ENV === 'development') {
        newUrl = url.replace('proxy', 'proxy');
      } else {
        newUrl = url?.includes('http') ? url : (process.env.VUE_APP_PROXY_URL || origin) + url;
      }
      return newUrl;
    };
    const getSpaceDetail = async () => {
      const { data } = await getSpaceServiceDetail(spaceId.value, isDisabled ? '' : userInfo.value.userId);
      spaceDetail.value = data;
      spaceDetail.value.serviceUrl = data.serviceUrl?.includes('http') ? data.serviceUrl : origin + data.serviceUrl;
      spaceDetail.value.proxyUrl = computeProxyUrl(data.proxyUrl);
      getRootCodes(data.areaCode);
      setTimeout(() => {
        descriptionChanges.value = false;
      }, 200);
      mapOptions.value = {
        url: spaceDetail.value.proxyUrl,
        extent: [data.xMin, data.yMin, data.xMax, data.yMax],
        layer: data.layers,
        center: [data.xCenter, data.yCenter],
        projection: data.coordinate,
        type: data.serviceTypeName,
        serviceName: data.serviceName,
        id: spaceId.value,
        serviceTypeId: data.serviceTypeId,
      };
    };

    const getTags = async () => {
      const { data } = await getTagsWithCount();
      tagList.value = data.map((x) => x.tagName);
    };

    const doTags = async (tags, type) => {
      try {
        const { code } = await saveTag({
          serviceId: spaceId.value,
          tagInfos: tags,
        });
        if (code === 0) {
          ElMessage.success(type === 1 ? '添加标签成功' : '删除标签成功');
          await getSpaceDetail();
          dialogVisible.value = false;
        }
      } catch (e) {
        ElMessage.error(e);
        dialogVisible.value = false;
      }
    };

    const submitTags = async () => {
      let tags = [...tagArr.value, ...tagString.value.split(',')].filter((x) => x);
      tags = [...new Set(tags)];
      tags = tags.map((x) => ({ tagName: x }));
      await doTags(tags, 1);
    };

    const deleteTags = async (item) => {
      let tags = spaceDetail.value.tags.filter((x) => x !== item).filter((x) => x);
      tags = tags.map((x) => ({ tagName: x }));
      await doTags(tags, 0);
    };

    watch(dialogVisible, async (nn) => {
      if (nn) {
        await getTags();
        tagArr.value = spaceDetail.value.tags;
      } else {
        tagString.value = '';
        tagArr.value = [];
      }
    });
    const updateDescription = async () => {
      if (!descriptionChanges.value) {
        return;
      }
      try {
        const { data, code } = await updateSpaceDetail({
          id: spaceDetail.value.id,
          description: spaceDetail.value.description,
        });
        console.log(data);
        if (code === 0) {
          ElMessage.success('更新详情成功');
          descriptionChanges.value = false;
        }
      } catch (e) {
        ElMessage.error(e);
      }
    };
    const updateDatasource = async () => {
      if (!dataSourceChanges.value) {
        return;
      }
      try {
        const { data, code } = await updateSpaceDetail({
          id: spaceDetail.value.id,
          dataSource: spaceDetail.value.dataSource,
        });
        console.log(data);
        if (code === 0) {
          ElMessage.success('更新数据来源成功');
          dataSourceChanges.value = false;
        }
      } catch (e) {
        ElMessage.error(e);
      }
    };

    const updateAreaCode = async () => {
      try {
        const { code } = await updateSpaceDetail({
          id: spaceDetail.value.id,
          areaCode: areaList.value.area,
        });
        if (code === 0) {
          ElMessage.success('更新行政区划成功');
        }
      } catch (e) {
        ElMessage.error(e);
      }
    };

    getTags();

    getSpaceDetail();

    const copyUrl = (idString) => {
      const descriptions = document.querySelector(idString);
      descriptions.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        ElMessage.success('复制至剪切板');
      }
    };

    const handleBack = () => {
      router.push('/service-resource-center');
    };
    const applyDialogRef = ref();
    const apply = () => {
      applyDialogRef.value.handleOpen({
        serviceId: spaceId.value,
      });
    };

    const picError = () => {
      console.log('pic-load-errors');
      spaceDetail.value.serviceThum = '';
    };

    watch(
      () => spaceDetail.value.areaCode,
      (nn) => {
        areasCodes.value.forEach((x) => {
          if (x.areaCode === nn) {
            showAreaName.value = x.areaName;
          }
        });
      },
    );
    watch(areasCodes, () => {
      areasCodes.value.forEach((x) => {
        if (x.areaCode === spaceDetail.value.areaCode) {
          showAreaName.value = x.areaName;
        }
      });
    });
    return {
      spaceId,
      spaceDetail,
      dialogVisible,
      tagArr,
      tagList,
      tagString,
      submitTags,
      updateDescription,
      deleteTags,
      isDisabled,
      IdentifyButton,
      getIdentifyButtonStatus,
      applyDialogRef,
      copyUrl,
      handleBack,
      apply,
      NoImage,
      origin,
      picError,
      mapOptions,
      showAreaName,
      areasCodes,
      updateAreaCode,
      updateDatasource,
      cascaderProps,
      casCodeData,
      resolveCodes,
      casChange,
      areaList,
      changeCity,
    };
  },
});
</script>

<style lang="scss" scoped>
.space-detail {
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 700px;
  background-color: white;
  overflow-y: auto;
  display: flex;
  .back-icon {
    font-size: 14px;
    font-weight: bolder;
    margin-right: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  & > div {
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }
  &-content {
    flex: 1;
    width: 450px;
    padding: 10px 30px;
    box-shadow: 5px 0 12px rgba(0, 0, 0, 0.4);
    height: 100%;
    overflow-y: auto;
    .content-item {
      margin-bottom: 20px;
      width: 100%;

      .link {
        padding: 0 8px;
      }
      .tab-item {
        min-height: 30px;
        line-height: 30px;
        width: 100%;
        margin-bottom: 5px;
        label {
          display: inline-block;
          width: 70px;
          text-align: left;
          color: rgba(0, 0, 0, 0.6);
        }
        & > span {
          display: inline-block;
          min-width: 140px;
        }
        .point {
          display: inline-block;
          min-width: 140px;
          margin-right: 10px;
        }
        .service-tags {
          width: 70%;
          .tag-item {
            padding: 2px 5px;
            height: 24px;
            margin: 5px;
            line-height: 20px;
            border: solid 1px rgba(0, 0, 0, 0.2);
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            display: inline-block;
            min-width: 40px;
            text-align: center;
            &:hover {
              cursor: pointer;
              background-color: rgba(0, 0, 0, 0.02);
            }
            .el-input,
            .el-input--small {
              width: 350px;
            }
          }
          .add-tag {
            background-color: transparent;
            border: dashed 1px rgba(0, 0, 0, 0.2);
            &:hover {
              border: dashed 1px #006eff;
              color: #006eff;
            }
          }
        }
      }
    }
  }
  &-pic {
    margin-left: 10px;
    flex: 3;
    //background-color: #006eff;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
.page-size {
  margin: 50px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  height: calc(100vh - 210px);
  width: calc(100vw - 100px);
  background: linear-gradient(135deg, #002b52 0%, #101217 80%);
  color: #979da6;
  font-size: 14px;
  overflow-y: hidden;
  .content-item {
    //position: relative;
  }
  label {
    color: white !important;
  }
  .space-detail-content {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .tag-item {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  .space-detail-pic {
    margin: 0;
  }
}
.detail-title {
  display: flex;
  justify-items: center;
  align-items: center;
  h3 {
    max-width: calc(100% - 80px);
  }
  .apply-btn {
    margin-left: auto;
    margin-block-start: 1em;
    margin-block-end: 1em;
    background: rgba($color: #000000, $alpha: 0);
    color: #0059b5;
    border: 1px solid #0059b5;
    cursor: pointer;
  }
}
</style>
